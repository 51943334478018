import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import SlickItem from "../components/SlickItem";
import { DriveSliderItems } from "../constants";
import useSendData from "../hooks/useSendData";
import Toast from "./Toast";

export const useOutsideClick = (callback: () => void) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    };

    document.addEventListener("mouseup", handleClickOutside);
    document.addEventListener("touchend", handleClickOutside);

    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
      document.removeEventListener("touchend", handleClickOutside);
    };
  }, [callback]);

  return ref;
};

type FormHeaderProps = {
  onClick?: () => void;
  onBack?: () => void;
  firstStep?: boolean;
  step_4?: boolean;
};

const FormHeader: React.FC<FormHeaderProps> = ({
  onClick,
  firstStep,
  onBack,
  step_4,
}) => {
  return (
    <div className="form_start_header">
      {!step_4 ? (
        <span
          onClick={onBack}
          style={{
            cursor: "pointer",
            opacity: firstStep ? 0 : 1,
            width: 30,
          }}
        >
          <img src="assets/images/icons/arrow back.svg" alt="back" />
        </span>
      ) : null}

      <span className="form_start_header_title">Let's Start</span>
      <span style={{ cursor: "pointer" }} onClick={onClick}>
        <img src="assets/images/icons/clear.svg" alt="back" />
      </span>
    </div>
  );
};

type QuizModalProps = {
  packageName?: string;
  quiz: boolean;
  onClose?: (quiz: boolean) => void;
};

const QuizModal: React.FC<QuizModalProps> = ({
  quiz,
  onClose,
  packageName,
}) => {
  const { sendData } = useSendData();

  const [close, setClose] = useState(quiz);
  const [step_1, setStep_1] = useState(true);
  const [step_2, setStep_2] = useState(false);
  const [step_2_2, setStep_2_2] = useState(false);
  const [step_3, setStep_3] = useState(false);
  const [step_4, setStep_4] = useState(false);
  const [firstStep, setFirstStep] = useState(true);
  const [select, setSelect] = useState(0);
  const [select_1, setSelect_1] = useState(true);
  const [select_2, setSelect_2] = useState(false);
  const [select_3, setSelect_3] = useState(false);
  const [doors, setDoors] = useState(0);
  const [garageDors, setGarageDoors] = useState(0);
  const [windows, setWindows] = useState(0);
  const [checkSingle, setCheckSingle] = useState(false);
  const [checkTownhome, setCheckTownhome] = useState(false);
  const [checkCondo, setCheckCondo] = useState(false);
  const [checkApartment, setCheckApartment] = useState(false);
  const [checkTrailer, setCheckTrailer] = useState(false);
  const [checkOther, setCheckOther] = useState(false);
  const [residenceType, setResidenceType] = useState("");
  const [systemType, setSystemType] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [toast, setToast] = useState(false);
  const [userDataToast, setUserDataToast] = useState(false);

  useEffect(() => {
    if (quiz) {
      setStep_1(true);
      setStep_2(false);
      setStep_3(false);
      setFirstStep(true);
      setSelect(0);
      setSelect_1(true);
      setSelect_2(false);
      setSelect_3(false);

      setDoors(0);
      setGarageDoors(0);
      setWindows(0);
      setCheckSingle(false);
      setCheckTownhome(false);
      setCheckCondo(false);
      setCheckApartment(false);
      setCheckTrailer(false);
      setCheckOther(false);
      setResidenceType("");
      setSystemType("");

      setName("");
      setPhone("");
      setEmail("");
    }
  }, [quiz]);

  const onClick = useCallback(() => {
    setClose(false);
    if (onClose) {
      onClose(false);
    }
  }, [onClose]);

  const validateResidence =
    checkSingle ||
    checkTownhome ||
    checkCondo ||
    checkApartment ||
    checkTrailer ||
    checkOther;

  const validateUnits = doors !== 0 || garageDors !== 0 || windows !== 0;

  const [isMobile] = useState(window.innerWidth < 768);
  const navigate = useNavigate();

  const onResidenceType = useCallback(
    (value: string) => {
      if (value === "Single Family") {
        setCheckSingle(!checkSingle);
        setCheckTownhome(false);
        setCheckCondo(false);
        setCheckApartment(false);
        setCheckTrailer(false);
        setCheckOther(false);
        setResidenceType(value);
        setToast(false);
        if (checkSingle) {
          setResidenceType("");
        }
      }
      if (value === "Townhome") {
        setCheckTownhome(!checkTownhome);
        setCheckSingle(false);
        setCheckCondo(false);
        setCheckApartment(false);
        setCheckTrailer(false);
        setCheckOther(false);
        setResidenceType(value);
        setToast(false);
        if (checkTownhome) {
          setResidenceType("");
        }
      }
      if (value === "Condo") {
        setCheckCondo(!checkCondo);
        setCheckTownhome(false);
        setCheckSingle(false);
        setCheckApartment(false);
        setCheckTrailer(false);
        setCheckOther(false);
        setResidenceType(value);
        setToast(false);
        if (checkCondo) {
          setResidenceType("");
        }
      }
      if (value === "Apartment") {
        setCheckApartment(!checkApartment);
        setCheckCondo(false);
        setCheckTownhome(false);
        setCheckSingle(false);
        setCheckTrailer(false);
        setCheckOther(false);
        setResidenceType(value);
        setToast(false);
        if (checkApartment) {
          setResidenceType("");
        }
      }
      if (value === "Trailer") {
        setCheckTrailer(!checkTrailer);
        setCheckApartment(false);
        setCheckCondo(false);
        setCheckTownhome(false);
        setCheckSingle(false);
        setCheckOther(false);
        setResidenceType(value);
        setToast(false);
        if (checkTrailer) {
          setResidenceType("");
        }
      }
      if (value === "Other") {
        setCheckOther(!checkOther);
        setCheckTrailer(false);
        setCheckApartment(false);
        setCheckCondo(false);
        setCheckTownhome(false);
        setCheckSingle(false);
        setResidenceType(value);
        setToast(false);
        if (checkOther) {
          setResidenceType("");
        }
      }
    },
    [
      checkSingle,
      checkTownhome,
      checkCondo,
      checkApartment,
      checkTrailer,
      checkOther,
    ]
  );

  const onDoorsPlus = useCallback(() => {
    setToast(false);
    setDoors(doors + 1);
  }, [doors]);

  const onDoorsMinus = useCallback(() => {
    if (doors > 0) {
      setDoors(doors - 1);
    } else {
      setDoors(0);
    }
  }, [doors]);

  const onGarageDoorsPlus = useCallback(() => {
    setGarageDoors(garageDors + 1);
    setToast(false);
  }, [garageDors]);

  const onGarageDoorsMinus = useCallback(() => {
    if (garageDors > 0) {
      setGarageDoors(garageDors - 1);
    } else {
      setGarageDoors(0);
    }
  }, [garageDors]);

  const onWindowsPlus = useCallback(() => {
    setWindows(windows + 1);
    setToast(false);
  }, [windows]);

  const onWindowsMinus = useCallback(() => {
    if (windows > 0) {
      setWindows(windows - 1);
    } else {
      setWindows(0);
    }
  }, [windows]);

  const onStep_1 = useCallback(() => {
    setStep_1(true);
    setStep_2(false);
    setStep_2_2(false);
    setStep_3(false);
    setStep_4(false);
    setFirstStep(true);
  }, []);

  const onStep_2 = useCallback(() => {
    setToast(false);
    setStep_1(false);
    setStep_3(false);
    setStep_4(false);
    setStep_2_2(false);
    setStep_2(true);
    setFirstStep(false);
  }, []);

  const onStep_2_2 = useCallback(() => {
    if (!validateResidence) {
      setToast(true);
    } else {
      setStep_1(false);
      setStep_3(false);
      setStep_4(false);
      setStep_2(false);
      setStep_2_2(true);
      setFirstStep(false);
      setToast(false);
    }
  }, [validateResidence]);

  const onStep_3 = useCallback(() => {
    setToast(false);
    setStep_1(false);
    setStep_2(false);
    setStep_2_2(false);
    setStep_4(false);
    setStep_3(true);
    setFirstStep(false);
  }, []);

  const onStep_4 = useCallback(() => {
    setStep_1(false);
    setStep_2(false);
    setStep_2_2(false);
    setStep_3(false);
    setStep_4(true);
    setFirstStep(false);
  }, []);

  const onSystemType = useCallback(
    (systemType: string) => {
      setSystemType(systemType);
      onStep_2();
    },
    [onStep_2]
  );

  const onSelect_1 = useCallback(() => {
    setSelect_1(true);
    setSelect_2(false);
    setSelect_3(false);
    onSystemType("Home Security System");
  }, [onSystemType]);

  const onSelect_2 = useCallback(() => {
    setSelect_1(false);
    setSelect_2(true);
    setSelect_3(false);
    onStep_2();
    onSystemType("Smart Home System");
  }, [onSystemType, onStep_2]);

  const onSelect_3 = useCallback(() => {
    setSelect_1(false);
    setSelect_2(false);
    setSelect_3(true);
    onStep_2();
    onSystemType("Security & Smart Home System");
  }, [onSystemType, onStep_2]);

  const validUserData = name !== "" && phone !== "" && email !== "";

  const onHideSuccess = useCallback(() => {
    onClick();
  }, [onClick]);

  useEffect(() => {
    setClose(quiz);
    if (packageName) {
      setSystemType(packageName);
      if (packageName === "Starter Home Pack") {
        setSelect(0);
      }
      if (packageName === "Average Smart Pack") {
        setSelect(1);
      }
      if (packageName === "Premiere Smart Home Pack") {
        setSelect(2);
      }
      onStep_2();
    }
  }, [quiz, packageName, onStep_2]);

  const onSubmit = useCallback(() => {
    if (!validUserData) {
      setUserDataToast(true);
    } else {
      setUserDataToast(false);
      onStep_4();
      setTimeout(onHideSuccess, 3000);
      sendData({
        name,
        phone,
        message: JSON.stringify({
          email,
          systemType,
          residenceType,
          doors,
          garageDors,
          windows,
        }),
      });
    }
  }, [
    validUserData,
    sendData,
    name,
    phone,
    email,
    systemType,
    residenceType,
    doors,
    garageDors,
    windows,
    onStep_4,
    onHideSuccess,
  ]);

  const onActive = useCallback(
    (index: number) => {
      if (index === 0) {
        setSystemType("Starter Home Pack");
      }
      if (index === 1) {
        setSystemType("Average Smart Pack");
      }
      if (index === 2) {
        setSystemType("Premiere Smart Home Pack");
      }

      setSelect(index);

      onStep_2();
    },
    [onStep_2]
  );

  const onConfirm = useCallback(() => {
    if (!validateUnits && isMobile) {
      setToast(true);
    }
    if (!validateResidence && !isMobile) {
      setToast(true);
    } else {
      setToast(false);
      onStep_3();
      setStep_2_2(false);
    }
  }, [onStep_3, setToast, validateUnits, isMobile, validateResidence]);

  const onBack = useCallback(() => {
    setToast(false);
    if (step_4) {
      onStep_3();
    }
    if (step_3) {
      if (isMobile) {
        onStep_2_2();
      } else {
        onStep_2();
      }
    }
    if (step_2) {
      onStep_1();
    }
    if (step_2_2 && isMobile) {
      onStep_2();
    }
  }, [
    onStep_1,
    onStep_2,
    onStep_2_2,
    onStep_3,
    step_2,
    step_3,
    step_4,
    step_2_2,
    isMobile,
  ]);

  if (!close) return null;

  return (
    <div
      id="form_start_wrapper"
      className=""
      style={{
        opacity: close ? 1 : 0 /* , display: close ? "flex" : "none"  */,
      }}
    >
      <div className="form_start_box">
        <FormHeader
          onClick={onClick}
          firstStep={firstStep}
          onBack={onBack}
          step_4={step_4}
        />
        <div className="form_start_content">
          {step_1 ? (
            <>
              <div className="form_start_content_steps">
                <div className="step step1">
                  <span>1</span>
                  <span style={{ marginTop: 8 }}>Home Pack</span>
                </div>
                <div className="step_line"></div>
                <div className="step">
                  <span>2</span>
                  <span style={{ marginTop: 8 }}>
                    Residence / Doors & windows
                  </span>
                </div>
                <div className="step_line"></div>
                <div className="step step3">
                  <span>3</span>
                  <span style={{ marginTop: 8 }}>Finalize</span>
                </div>
              </div>
              <div className="system_slider" style={{ paddingLeft: 15 }}>
                {isMobile ? (
                  step_1 ? (
                    <div className="sys_products">
                      <div className="ss_item selected" onClick={onSelect_1}>
                        <label htmlFor="sys_product_1">
                          <div className="ss_item_img">
                            <img src="assets/images/slide_1.jpg" alt="img" />
                            <div className="mob">
                              <span className="mob_ss_item_header">
                                HOME SECURITY SYSTEM
                              </span>
                              <div className="mob_ss_item_meta_desc">
                                The Starter Home Security System is a great way
                                to start your home security journey.
                              </div>
                            </div>
                          </div>
                          <div
                            className="ss_item_meta"
                            style={{ backgroundColor: "#000" }}
                          >
                            <div className="ss_item_meta_action">
                              <span style={{ width: 48 }}>
                                {select_1 ? (
                                  <img
                                    src="assets/images/icons/arrow-in-circle.svg"
                                    alt="circle"
                                  />
                                ) : (
                                  <img
                                    src="assets/images/icons/arrow-in-circle-default.svg"
                                    alt="circle"
                                  />
                                )}
                              </span>
                            </div>
                          </div>
                        </label>
                      </div>

                      <div className="ss_item" onClick={onSelect_2}>
                        <label htmlFor="sys_product_2">
                          <div className="ss_item_img">
                            <img src="assets/images/slide_2.jpg" alt="img" />

                            <div className="mob">
                              <span className="mob_ss_item_header">
                                SMART HOME SYSTEM
                              </span>
                              <div className="mob_ss_item_meta_desc">
                                If you're looking for the next level in home
                                protection, this is the perfect match for you.
                              </div>
                            </div>
                          </div>
                          <div
                            className="ss_item_meta"
                            style={{ backgroundColor: "#000" }}
                          >
                            <div className="ss_item_meta_action">
                              <span style={{ width: 48 }}>
                                {select_2 ? (
                                  <img
                                    src="assets/images/icons/arrow-in-circle.svg"
                                    alt="circle"
                                  />
                                ) : (
                                  <img
                                    src="assets/images/icons/arrow-in-circle-default.svg"
                                    alt="circle"
                                  />
                                )}
                              </span>
                            </div>
                          </div>
                        </label>
                      </div>

                      <div className="ss_item" onClick={onSelect_3}>
                        <label htmlFor="sys_product_3">
                          <div className="ss_item_img">
                            <img src="assets/images/slide_3.jpg" alt="img" />

                            <div className="mob">
                              <span className="mob_ss_item_header">
                                SECURITY &amp; SMART HOME SYSTEM
                              </span>
                              <div className="mob_ss_item_meta_desc">
                                You will have full control with the Ultimate
                                Home Security System.
                              </div>
                            </div>
                          </div>
                          <div
                            className="ss_item_meta"
                            style={{ backgroundColor: "#000" }}
                          >
                            <div className="ss_item_meta_action">
                              <span style={{ width: 48 }}>
                                {select_3 ? (
                                  <img
                                    src="assets/images/icons/arrow-in-circle.svg"
                                    alt="circle"
                                  />
                                ) : (
                                  <img
                                    src="assets/images/icons/arrow-in-circle-default.svg"
                                    alt="circle"
                                  />
                                )}
                              </span>
                            </div>
                          </div>
                          <input
                            type="radio"
                            id="sys_product_3"
                            name="ss_security_system"
                            value="SECURITY &amp; SMART HOME SYSTEM"
                          />
                        </label>
                      </div>
                    </div>
                  ) : null
                ) : (
                  <div className="slick-track">
                    {DriveSliderItems.map((item, index) => {
                      return (
                        <SlickItem
                          key={index}
                          title={item.title}
                          description={item.description}
                          onClick={() => onActive(index)}
                          icon={item.icon}
                          quiz
                          selected={select === item.index}
                        />
                      );
                    })}
                  </div>
                )}
              </div>
            </>
          ) : null}
          {step_2 ? (
            <>
              <div className="form_start_content_steps">
                <div className="step step1">
                  <span>
                    <svg
                      width="38"
                      height="38"
                      viewBox="0 0 38 38"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19 38C29.4934 38 38 29.4934 38 19C38 8.50659 29.4934 0 19 0C8.50659 0 0 8.50659 0 19C0 29.4934 8.50659 38 19 38ZM13.717 19.3217L16.464 22.0687L24.2857 14.255C24.4336 14.1067 24.6344 14.0234 24.8438 14.0234C25.0532 14.0234 25.254 14.1067 25.402 14.255C25.7107 14.5637 25.7107 15.0625 25.402 15.3712L17.0261 23.7471C16.7174 24.0558 16.2186 24.0558 15.9099 23.7471L12.6007 20.4379C12.292 20.1292 12.292 19.6304 12.6007 19.3217C12.7486 19.1734 12.9494 19.0901 13.1588 19.0901C13.3682 19.0901 13.569 19.1734 13.717 19.3217Z"
                        fill="#51CC47"
                      />
                    </svg>
                  </span>
                  <span style={{ marginTop: 8 }}>Home Pack</span>
                </div>
                <div className="step_line step_line_success"></div>
                <div className="step step2">
                  <span>2</span>
                  <span style={{ marginTop: 8 }}>
                    Residence / Doors & windows
                  </span>
                </div>
                <div className="step_line"></div>
                <div className="step step3">
                  <span>3</span>
                  <span style={{ marginTop: 8 }}>Finalize</span>
                </div>
              </div>

              <div className="product_type_select_box">
                {!isMobile ? (
                  <div className="product_type_select_box_header pc_version">
                    <div className="product_type_title">
                      <span>Residence Type</span>
                      <span>Choose one</span>
                    </div>
                    <div className="product_type_line"></div>
                    <div className="product_type_title">
                      <span>Doors & Windows</span>
                      <span>Choose any</span>
                    </div>
                  </div>
                ) : null}
                <div
                  className="product_type_select_box_content"
                  style={{
                    borderRadius: 20,
                    backgroundColor: isMobile
                      ? "rgba(79, 81, 85, 1)"
                      : "background: rgba(48, 49, 51, 1)",
                  }}
                >
                  {isMobile ? (
                    <div
                      className="product_type_select_box_header"
                      style={{ paddingBottom: 0, marginBottom: -20 }}
                    >
                      <div className="product_type_title">
                        <span>Residence Type</span>
                        <span>Choose one</span>
                      </div>
                    </div>
                  ) : null}
                  <div className="product_type_select_radio">
                    {toast ? (
                      <div
                        style={{
                          position: "absolute",
                          width: 310,
                          bottom: "80%",
                          left: 38,
                        }}
                      >
                        <Toast text="Please select one!" />
                      </div>
                    ) : null}
                    <p>
                      <input
                        type="radio"
                        id="product_type_select_radio1"
                        onClick={() => onResidenceType("Single Family")}
                        onChange={() => setResidenceType("Single Family")}
                        checked={checkSingle}
                      />
                      <label htmlFor="product_type_select_radio1">
                        Single Family
                      </label>
                    </p>
                    <p>
                      <input
                        type="radio"
                        id="product_type_select_radio2"
                        onClick={() => onResidenceType("Townhome")}
                        onChange={() => setResidenceType("Townhome")}
                        checked={checkTownhome}
                      />
                      <label htmlFor="product_type_select_radio2">
                        Townhome
                      </label>
                    </p>
                    <p>
                      <input
                        type="radio"
                        id="product_type_select_radio3"
                        onClick={() => onResidenceType("Condo")}
                        onChange={() => setResidenceType("Condo")}
                        checked={checkCondo}
                      />
                      <label htmlFor="product_type_select_radio3">Condo</label>
                    </p>
                    <p>
                      <input
                        type="radio"
                        id="product_type_select_radio4"
                        onClick={() => onResidenceType("Apartment")}
                        onChange={() => setResidenceType("Apartment")}
                        checked={checkApartment}
                      />
                      <label htmlFor="product_type_select_radio4">
                        Apartment
                      </label>
                    </p>
                    <p>
                      <input
                        type="radio"
                        id="product_type_select_radio5"
                        onClick={() => onResidenceType("Trailer")}
                        onChange={() => setResidenceType("Trailer")}
                        checked={checkTrailer}
                      />
                      <label htmlFor="product_type_select_radio5">
                        Trailer
                      </label>
                    </p>
                    <p>
                      <input
                        type="radio"
                        id="product_type_select_radio6"
                        onClick={() => onResidenceType("Other")}
                        onChange={() => setResidenceType("Other")}
                        checked={checkOther}
                      />
                      <label htmlFor="product_type_select_radio6">Other</label>
                    </p>
                  </div>

                  {!isMobile ? (
                    <>
                      <div className="product_type_select_line"></div>

                      <div className="product_type_select_box_header mob-only">
                        <div className="product_type_title">
                          <span>Doors & Windows</span>
                          <span>Choose any</span>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {!isMobile ? (
                    <div className="product_type_select_nums">
                      <div className="product_type_select_nums_row">
                        <span className="label">Entry doors</span>
                        <span className="minus" onClick={onDoorsMinus}>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_1_5035)">
                              <path
                                d="M10 0C15.5275 0 20 4.4725 20 10C20 15.5275 15.5275 20 10 20C4.4725 20 0 15.5275 0 10C0 4.4725 4.4725 0 10 0ZM10 18.7694C14.8244 18.7694 18.75 14.8237 18.75 10C18.75 5.17625 14.8244 1.25 10 1.25C5.17562 1.25 1.25 5.17562 1.25 10C1.25 14.8244 5.17562 18.7694 10 18.7694ZM9.31625 10.625H5.625C5.27313 10.625 5 10.3512 5 10C5 9.64875 5.27375 9.375 5.625 9.375H14.375C14.7269 9.375 15 9.64875 15 10C15 10.3512 14.7262 10.625 14.375 10.625H9.31625Z"
                                fill="white"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1_5035">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                        <div className="user-select-none">{doors}</div>
                        <span className="plus" onClick={onDoorsPlus}>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_1_4995)">
                              <path
                                d="M10 0C4.4775 0 0 4.47688 0 10C0 15.5231 4.47688 20 10 20C15.5231 20 20 15.5231 20 10C20 4.47688 15.5231 0 10 0ZM10 18.77C5.175 18.77 1.25 14.825 1.25 10C1.25 5.175 5.175 1.25 10 1.25C14.825 1.25 18.75 5.175 18.75 10C18.75 14.825 14.825 18.77 10 18.77ZM14.375 9.375H10.625V5.625C10.625 5.28 10.345 5 10 5C9.655 5 9.375 5.28 9.375 5.625V9.375H5.625C5.28 9.375 5 9.655 5 10C5 10.345 5.28 10.625 5.625 10.625H9.375V14.375C9.375 14.72 9.655 15 10 15C10.345 15 10.625 14.72 10.625 14.375V10.625H14.375C14.72 10.625 15 10.345 15 10C15 9.655 14.72 9.375 14.375 9.375Z"
                                fill="#51CC47"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1_4995">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                      </div>

                      <div className="product_type_select_nums_row">
                        <span className="label">Garage doors</span>
                        <span className="minus" onClick={onGarageDoorsMinus}>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_1_5035)">
                              <path
                                d="M10 0C15.5275 0 20 4.4725 20 10C20 15.5275 15.5275 20 10 20C4.4725 20 0 15.5275 0 10C0 4.4725 4.4725 0 10 0ZM10 18.7694C14.8244 18.7694 18.75 14.8237 18.75 10C18.75 5.17625 14.8244 1.25 10 1.25C5.17562 1.25 1.25 5.17562 1.25 10C1.25 14.8244 5.17562 18.7694 10 18.7694ZM9.31625 10.625H5.625C5.27313 10.625 5 10.3512 5 10C5 9.64875 5.27375 9.375 5.625 9.375H14.375C14.7269 9.375 15 9.64875 15 10C15 10.3512 14.7262 10.625 14.375 10.625H9.31625Z"
                                fill="white"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1_5035">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                        <div className="user-select-none">{garageDors}</div>
                        <span className="plus" onClick={onGarageDoorsPlus}>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_1_4995)">
                              <path
                                d="M10 0C4.4775 0 0 4.47688 0 10C0 15.5231 4.47688 20 10 20C15.5231 20 20 15.5231 20 10C20 4.47688 15.5231 0 10 0ZM10 18.77C5.175 18.77 1.25 14.825 1.25 10C1.25 5.175 5.175 1.25 10 1.25C14.825 1.25 18.75 5.175 18.75 10C18.75 14.825 14.825 18.77 10 18.77ZM14.375 9.375H10.625V5.625C10.625 5.28 10.345 5 10 5C9.655 5 9.375 5.28 9.375 5.625V9.375H5.625C5.28 9.375 5 9.655 5 10C5 10.345 5.28 10.625 5.625 10.625H9.375V14.375C9.375 14.72 9.655 15 10 15C10.345 15 10.625 14.72 10.625 14.375V10.625H14.375C14.72 10.625 15 10.345 15 10C15 9.655 14.72 9.375 14.375 9.375Z"
                                fill="#51CC47"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1_4995">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                      </div>

                      <div className="product_type_select_nums_row">
                        <span className="label">Windows</span>
                        <span className="minus" onClick={onWindowsMinus}>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_1_5035)">
                              <path
                                d="M10 0C15.5275 0 20 4.4725 20 10C20 15.5275 15.5275 20 10 20C4.4725 20 0 15.5275 0 10C0 4.4725 4.4725 0 10 0ZM10 18.7694C14.8244 18.7694 18.75 14.8237 18.75 10C18.75 5.17625 14.8244 1.25 10 1.25C5.17562 1.25 1.25 5.17562 1.25 10C1.25 14.8244 5.17562 18.7694 10 18.7694ZM9.31625 10.625H5.625C5.27313 10.625 5 10.3512 5 10C5 9.64875 5.27375 9.375 5.625 9.375H14.375C14.7269 9.375 15 9.64875 15 10C15 10.3512 14.7262 10.625 14.375 10.625H9.31625Z"
                                fill="white"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1_5035">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                        <div className="user-select-none">{windows}</div>
                        <span className="plus" onClick={onWindowsPlus}>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_1_4995)">
                              <path
                                d="M10 0C4.4775 0 0 4.47688 0 10C0 15.5231 4.47688 20 10 20C15.5231 20 20 15.5231 20 10C20 4.47688 15.5231 0 10 0ZM10 18.77C5.175 18.77 1.25 14.825 1.25 10C1.25 5.175 5.175 1.25 10 1.25C14.825 1.25 18.75 5.175 18.75 10C18.75 14.825 14.825 18.77 10 18.77ZM14.375 9.375H10.625V5.625C10.625 5.28 10.345 5 10 5C9.655 5 9.375 5.28 9.375 5.625V9.375H5.625C5.28 9.375 5 9.655 5 10C5 10.345 5.28 10.625 5.625 10.625H9.375V14.375C9.375 14.72 9.655 15 10 15C10.345 15 10.625 14.72 10.625 14.375V10.625H14.375C14.72 10.625 15 10.345 15 10C15 9.655 14.72 9.375 14.375 9.375Z"
                                fill="#51CC47"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1_4995">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                      </div>
                      <button onClick={onConfirm} className="confirm-button">
                        Confirm
                      </button>
                    </div>
                  ) : null}
                  {isMobile ? (
                    <div
                      className="sec_options_box_footer"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div className="security_step_back" onClick={onStep_1}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          viewBox="0 0 448 512"
                        >
                          <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                        </svg>
                      </div>

                      <div className="mob_process">
                        <span
                          className="dot1"
                          style={{ width: 14, borderRadius: 5 }}
                        ></span>
                        <span className="dot2"></span>
                      </div>

                      <div className="security_step_next" onClick={onStep_2_2}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          viewBox="0 0 448 512"
                        >
                          <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                        </svg>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </>
          ) : null}
          {step_2_2 ? (
            <>
              <div className="form_start_content_steps">
                <div className="step step1">
                  <span>
                    <svg
                      width="38"
                      height="38"
                      viewBox="0 0 38 38"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19 38C29.4934 38 38 29.4934 38 19C38 8.50659 29.4934 0 19 0C8.50659 0 0 8.50659 0 19C0 29.4934 8.50659 38 19 38ZM13.717 19.3217L16.464 22.0687L24.2857 14.255C24.4336 14.1067 24.6344 14.0234 24.8438 14.0234C25.0532 14.0234 25.254 14.1067 25.402 14.255C25.7107 14.5637 25.7107 15.0625 25.402 15.3712L17.0261 23.7471C16.7174 24.0558 16.2186 24.0558 15.9099 23.7471L12.6007 20.4379C12.292 20.1292 12.292 19.6304 12.6007 19.3217C12.7486 19.1734 12.9494 19.0901 13.1588 19.0901C13.3682 19.0901 13.569 19.1734 13.717 19.3217Z"
                        fill="#51CC47"
                      />
                    </svg>
                  </span>
                  <span style={{ marginTop: 8 }}>Home Pack</span>
                </div>
                <div className="step_line step_line_success"></div>
                <div className="step step2">
                  <span>2</span>
                  <span style={{ marginTop: 8 }}>
                    Residence / Doors & windows
                  </span>
                </div>
                <div className="step_line"></div>
                <div className="step step3">
                  <span>3</span>
                  <span style={{ marginTop: 8 }}>Finalize</span>
                </div>
              </div>
              <div
                className="product_type_select_box_content"
                style={{
                  borderRadius: 20,
                  backgroundColor: isMobile
                    ? "rgba(79, 81, 85, 1)"
                    : "background: rgba(48, 49, 51, 1)",
                }}
              >
                {toast && isMobile ? (
                  <div
                    style={{
                      position: "absolute",
                      width: 310,
                      bottom: "75%",
                      left: 38,
                    }}
                  >
                    <Toast text="Please select one!" />
                  </div>
                ) : null}
                <div
                  className="product_type_select_box_header"
                  style={{ paddingBottom: 30 }}
                >
                  <div className="product_type_title">
                    <span>Doors & Windows</span>
                    <span>Choose any</span>
                  </div>
                </div>
                <div className="product_type_select_nums">
                  <div className="product_type_select_nums_row">
                    <span className="label">Entry doors</span>
                    <span className="minus" onClick={onDoorsMinus}>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_1_5035)">
                          <path
                            d="M10 0C15.5275 0 20 4.4725 20 10C20 15.5275 15.5275 20 10 20C4.4725 20 0 15.5275 0 10C0 4.4725 4.4725 0 10 0ZM10 18.7694C14.8244 18.7694 18.75 14.8237 18.75 10C18.75 5.17625 14.8244 1.25 10 1.25C5.17562 1.25 1.25 5.17562 1.25 10C1.25 14.8244 5.17562 18.7694 10 18.7694ZM9.31625 10.625H5.625C5.27313 10.625 5 10.3512 5 10C5 9.64875 5.27375 9.375 5.625 9.375H14.375C14.7269 9.375 15 9.64875 15 10C15 10.3512 14.7262 10.625 14.375 10.625H9.31625Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1_5035">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    <div className="user-select-none">{doors}</div>
                    <span className="plus" onClick={onDoorsPlus}>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_1_4995)">
                          <path
                            d="M10 0C4.4775 0 0 4.47688 0 10C0 15.5231 4.47688 20 10 20C15.5231 20 20 15.5231 20 10C20 4.47688 15.5231 0 10 0ZM10 18.77C5.175 18.77 1.25 14.825 1.25 10C1.25 5.175 5.175 1.25 10 1.25C14.825 1.25 18.75 5.175 18.75 10C18.75 14.825 14.825 18.77 10 18.77ZM14.375 9.375H10.625V5.625C10.625 5.28 10.345 5 10 5C9.655 5 9.375 5.28 9.375 5.625V9.375H5.625C5.28 9.375 5 9.655 5 10C5 10.345 5.28 10.625 5.625 10.625H9.375V14.375C9.375 14.72 9.655 15 10 15C10.345 15 10.625 14.72 10.625 14.375V10.625H14.375C14.72 10.625 15 10.345 15 10C15 9.655 14.72 9.375 14.375 9.375Z"
                            fill="#51CC47"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1_4995">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                  </div>

                  <div className="product_type_select_nums_row">
                    <span className="label">Garage doors</span>
                    <span className="minus" onClick={onGarageDoorsMinus}>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_1_5035)">
                          <path
                            d="M10 0C15.5275 0 20 4.4725 20 10C20 15.5275 15.5275 20 10 20C4.4725 20 0 15.5275 0 10C0 4.4725 4.4725 0 10 0ZM10 18.7694C14.8244 18.7694 18.75 14.8237 18.75 10C18.75 5.17625 14.8244 1.25 10 1.25C5.17562 1.25 1.25 5.17562 1.25 10C1.25 14.8244 5.17562 18.7694 10 18.7694ZM9.31625 10.625H5.625C5.27313 10.625 5 10.3512 5 10C5 9.64875 5.27375 9.375 5.625 9.375H14.375C14.7269 9.375 15 9.64875 15 10C15 10.3512 14.7262 10.625 14.375 10.625H9.31625Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1_5035">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    <div className="user-select-none">{garageDors}</div>
                    <span className="plus" onClick={onGarageDoorsPlus}>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_1_4995)">
                          <path
                            d="M10 0C4.4775 0 0 4.47688 0 10C0 15.5231 4.47688 20 10 20C15.5231 20 20 15.5231 20 10C20 4.47688 15.5231 0 10 0ZM10 18.77C5.175 18.77 1.25 14.825 1.25 10C1.25 5.175 5.175 1.25 10 1.25C14.825 1.25 18.75 5.175 18.75 10C18.75 14.825 14.825 18.77 10 18.77ZM14.375 9.375H10.625V5.625C10.625 5.28 10.345 5 10 5C9.655 5 9.375 5.28 9.375 5.625V9.375H5.625C5.28 9.375 5 9.655 5 10C5 10.345 5.28 10.625 5.625 10.625H9.375V14.375C9.375 14.72 9.655 15 10 15C10.345 15 10.625 14.72 10.625 14.375V10.625H14.375C14.72 10.625 15 10.345 15 10C15 9.655 14.72 9.375 14.375 9.375Z"
                            fill="#51CC47"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1_4995">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                  </div>

                  <div className="product_type_select_nums_row">
                    <span className="label">Windows</span>
                    <span className="minus" onClick={onWindowsMinus}>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_1_5035)">
                          <path
                            d="M10 0C15.5275 0 20 4.4725 20 10C20 15.5275 15.5275 20 10 20C4.4725 20 0 15.5275 0 10C0 4.4725 4.4725 0 10 0ZM10 18.7694C14.8244 18.7694 18.75 14.8237 18.75 10C18.75 5.17625 14.8244 1.25 10 1.25C5.17562 1.25 1.25 5.17562 1.25 10C1.25 14.8244 5.17562 18.7694 10 18.7694ZM9.31625 10.625H5.625C5.27313 10.625 5 10.3512 5 10C5 9.64875 5.27375 9.375 5.625 9.375H14.375C14.7269 9.375 15 9.64875 15 10C15 10.3512 14.7262 10.625 14.375 10.625H9.31625Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1_5035">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    <div className="user-select-none">{windows}</div>
                    <span className="plus" onClick={onWindowsPlus}>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_1_4995)">
                          <path
                            d="M10 0C4.4775 0 0 4.47688 0 10C0 15.5231 4.47688 20 10 20C15.5231 20 20 15.5231 20 10C20 4.47688 15.5231 0 10 0ZM10 18.77C5.175 18.77 1.25 14.825 1.25 10C1.25 5.175 5.175 1.25 10 1.25C14.825 1.25 18.75 5.175 18.75 10C18.75 14.825 14.825 18.77 10 18.77ZM14.375 9.375H10.625V5.625C10.625 5.28 10.345 5 10 5C9.655 5 9.375 5.28 9.375 5.625V9.375H5.625C5.28 9.375 5 9.655 5 10C5 10.345 5.28 10.625 5.625 10.625H9.375V14.375C9.375 14.72 9.655 15 10 15C10.345 15 10.625 14.72 10.625 14.375V10.625H14.375C14.72 10.625 15 10.345 15 10C15 9.655 14.72 9.375 14.375 9.375Z"
                            fill="#51CC47"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1_4995">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                  </div>
                </div>
                <div
                  className="sec_options_box_footer"
                  style={{ justifyContent: "space-between" }}
                >
                  <div className="security_step_back" onClick={onStep_2}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      viewBox="0 0 448 512"
                    >
                      <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                    </svg>
                  </div>

                  <div className="mob_process">
                    <span className="dot1"></span>
                    <span
                      className="dot2"
                      style={{ width: 14, borderRadius: 5 }}
                    ></span>
                  </div>

                  <div className="security_step_next" onClick={onConfirm}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      viewBox="0 0 448 512"
                    >
                      <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                    </svg>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          {step_3 ? (
            <>
              <div className="form_start_content_steps">
                <div className="step step1">
                  <span>
                    <svg
                      width="38"
                      height="38"
                      viewBox="0 0 38 38"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19 38C29.4934 38 38 29.4934 38 19C38 8.50659 29.4934 0 19 0C8.50659 0 0 8.50659 0 19C0 29.4934 8.50659 38 19 38ZM13.717 19.3217L16.464 22.0687L24.2857 14.255C24.4336 14.1067 24.6344 14.0234 24.8438 14.0234C25.0532 14.0234 25.254 14.1067 25.402 14.255C25.7107 14.5637 25.7107 15.0625 25.402 15.3712L17.0261 23.7471C16.7174 24.0558 16.2186 24.0558 15.9099 23.7471L12.6007 20.4379C12.292 20.1292 12.292 19.6304 12.6007 19.3217C12.7486 19.1734 12.9494 19.0901 13.1588 19.0901C13.3682 19.0901 13.569 19.1734 13.717 19.3217Z"
                        fill="#51CC47"
                      />
                    </svg>
                  </span>
                  <span style={{ marginTop: 8 }}>Home Pack</span>
                </div>
                <div className="step_line step_line_success"></div>
                <div className="step step2">
                  <span>
                    <svg
                      width="38"
                      height="38"
                      viewBox="0 0 38 38"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19 38C29.4934 38 38 29.4934 38 19C38 8.50659 29.4934 0 19 0C8.50659 0 0 8.50659 0 19C0 29.4934 8.50659 38 19 38ZM13.717 19.3217L16.464 22.0687L24.2857 14.255C24.4336 14.1067 24.6344 14.0234 24.8438 14.0234C25.0532 14.0234 25.254 14.1067 25.402 14.255C25.7107 14.5637 25.7107 15.0625 25.402 15.3712L17.0261 23.7471C16.7174 24.0558 16.2186 24.0558 15.9099 23.7471L12.6007 20.4379C12.292 20.1292 12.292 19.6304 12.6007 19.3217C12.7486 19.1734 12.9494 19.0901 13.1588 19.0901C13.3682 19.0901 13.569 19.1734 13.717 19.3217Z"
                        fill="#51CC47"
                      />
                    </svg>
                  </span>
                  <span style={{ marginTop: 8 }}>
                    Residence / Doors & windows
                  </span>
                </div>
                <div className="step_line step_line_success"></div>
                <div className="step step3">
                  <span>3</span>
                  <span style={{ marginTop: 8 }}>Finalize</span>
                </div>
              </div>

              <div
                className="form_start_quote"
                style={{ position: "relative" }}
              >
                {userDataToast ? (
                  <Toast text="All fields are required!" />
                ) : null}
                <h4>Get a free quote</h4>
                <p>
                  Ready to turn your home into a smart home? Call now to get
                  started or fill out the form.
                </p>
                <div className="form_start_quote_inputs">
                  <div className="form_start_quote_input">
                    <input
                      type="text"
                      name="full_name"
                      id="form_start_quote_input_1"
                      placeholder="Name"
                      required
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                      style={{ width: "100%" }}
                    />
                  </div>

                  <div className="form_start_quote_input">
                    <input
                      type="phone"
                      name="phone"
                      id="form_start_quote_input_2"
                      placeholder="Phone"
                      required
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      style={{ width: "100%" }}
                    />
                  </div>

                  <div className="form_start_quote_input">
                    <input
                      type="email"
                      name="email"
                      id="form_start_quote_input_2"
                      placeholder="Email"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <p className="q_bottom">
                  Clicking the button below constitutes your electronic
                  signature and consent for Vivint and its parent company NRG to
                  use automated technology, including calls, texts and
                  prerecorded messages, to contact you at the number provided
                  about Vivint and NRG offers. Consent not required to make a
                  purchase. Up to 10msg/month. Reply 'STOP' to opt-out. See{" "}
                  <span
                    className="link"
                    onClick={() => navigate("/term-of-service")}
                  >
                    Terms of Service
                  </span>{" "}
                  and
                  <span className="link" onClick={() => navigate("/privacy")}>
                    {" "}
                    Privacy Policy
                  </span>
                  .
                </p>
                {isMobile ? (
                  <div className="form_action action_mobile">
                    <button
                      className="button button-green"
                      type="submit"
                      onClick={onSubmit}
                      style={{ width: "100%", justifyContent: "center" }}
                    >
                      Start Your Quote
                      <svg
                        width="22"
                        height="21"
                        viewBox="0 0 22 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.63867 11.3809H14.2617L9.36035 16.2822L10.6318 17.4922L17.625 10.499L10.6318 3.50586L9.40137 4.71582L14.2617 9.61719H3.63867V11.3809Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                  </div>
                ) : (
                  <div className="form_start_footer">
                    <button
                      className="confirm-button"
                      type="button"
                      onClick={onSubmit}
                    >
                      Start Your Quote
                    </button>
                  </div>
                )}
              </div>
            </>
          ) : null}
          {step_4 ? (
            <>
              <div className="form_start_content_steps">
                <div className="step step1">
                  <span>
                    <svg
                      width="38"
                      height="38"
                      viewBox="0 0 38 38"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19 38C29.4934 38 38 29.4934 38 19C38 8.50659 29.4934 0 19 0C8.50659 0 0 8.50659 0 19C0 29.4934 8.50659 38 19 38ZM13.717 19.3217L16.464 22.0687L24.2857 14.255C24.4336 14.1067 24.6344 14.0234 24.8438 14.0234C25.0532 14.0234 25.254 14.1067 25.402 14.255C25.7107 14.5637 25.7107 15.0625 25.402 15.3712L17.0261 23.7471C16.7174 24.0558 16.2186 24.0558 15.9099 23.7471L12.6007 20.4379C12.292 20.1292 12.292 19.6304 12.6007 19.3217C12.7486 19.1734 12.9494 19.0901 13.1588 19.0901C13.3682 19.0901 13.569 19.1734 13.717 19.3217Z"
                        fill="#51CC47"
                      />
                    </svg>
                  </span>
                  <span>Home Pack</span>
                </div>
                <div className="step_line step_line_success"></div>
                <div className="step step2">
                  <span>
                    <svg
                      width="38"
                      height="38"
                      viewBox="0 0 38 38"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19 38C29.4934 38 38 29.4934 38 19C38 8.50659 29.4934 0 19 0C8.50659 0 0 8.50659 0 19C0 29.4934 8.50659 38 19 38ZM13.717 19.3217L16.464 22.0687L24.2857 14.255C24.4336 14.1067 24.6344 14.0234 24.8438 14.0234C25.0532 14.0234 25.254 14.1067 25.402 14.255C25.7107 14.5637 25.7107 15.0625 25.402 15.3712L17.0261 23.7471C16.7174 24.0558 16.2186 24.0558 15.9099 23.7471L12.6007 20.4379C12.292 20.1292 12.292 19.6304 12.6007 19.3217C12.7486 19.1734 12.9494 19.0901 13.1588 19.0901C13.3682 19.0901 13.569 19.1734 13.717 19.3217Z"
                        fill="#51CC47"
                      />
                    </svg>
                  </span>
                  <span>Residence / Doors & windows</span>
                </div>
                <div className="step_line step_line_success"></div>
                <div className="step step3">
                  <span>
                    <svg
                      width="38"
                      height="38"
                      viewBox="0 0 38 38"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19 38C29.4934 38 38 29.4934 38 19C38 8.50659 29.4934 0 19 0C8.50659 0 0 8.50659 0 19C0 29.4934 8.50659 38 19 38ZM13.717 19.3217L16.464 22.0687L24.2857 14.255C24.4336 14.1067 24.6344 14.0234 24.8438 14.0234C25.0532 14.0234 25.254 14.1067 25.402 14.255C25.7107 14.5637 25.7107 15.0625 25.402 15.3712L17.0261 23.7471C16.7174 24.0558 16.2186 24.0558 15.9099 23.7471L12.6007 20.4379C12.292 20.1292 12.292 19.6304 12.6007 19.3217C12.7486 19.1734 12.9494 19.0901 13.1588 19.0901C13.3682 19.0901 13.569 19.1734 13.717 19.3217Z"
                        fill="#51CC47"
                      />
                    </svg>
                  </span>
                  <span onClick={onSubmit}>Finalize</span>
                </div>
              </div>

              <div className="form_quote_final">
                <div className="final_icon">
                  <svg
                    width="150"
                    height="150"
                    viewBox="0 0 150 150"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_1_4319)">
                      <path
                        opacity="0.5"
                        d="M75 0C60.1664 0 45.666 4.39867 33.3323 12.6398C20.9986 20.8809 11.3856 32.5943 5.70907 46.2987C0.0324965 60.0032 -1.45275 75.0832 1.44114 89.6318C4.33503 104.18 11.4781 117.544 21.967 128.033C32.456 138.522 45.8197 145.665 60.3683 148.559C74.9168 151.453 89.9968 149.968 103.701 144.291C117.406 138.614 129.119 129.001 137.36 116.668C145.601 104.334 150 89.8336 150 75C149.979 55.1154 142.07 36.0513 128.009 21.9907C113.949 7.93018 94.8847 0.0215068 75 0ZM75 137.5C62.6387 137.5 50.555 133.834 40.2769 126.967C29.9988 120.099 21.988 110.338 17.2576 98.9177C12.5271 87.4973 11.2894 74.9307 13.701 62.8069C16.1125 50.683 22.0651 39.5466 30.8059 30.8058C39.5466 22.065 50.6831 16.1125 62.8069 13.7009C74.9307 11.2893 87.4974 12.5271 98.9178 17.2575C110.338 21.988 120.099 29.9988 126.967 40.2769C133.834 50.5549 137.5 62.6387 137.5 75C137.482 91.5704 130.891 107.457 119.174 119.174C107.457 130.891 91.5705 137.482 75 137.5Z"
                        fill="#51CC47"
                      />
                      <path
                        d="M101.831 51.8312L62.5001 91.1625L48.1689 76.8312C46.9901 75.6928 45.4113 75.0628 43.7726 75.077C42.1339 75.0913 40.5663 75.7486 39.4075 76.9074C38.2487 78.0662 37.5914 79.6338 37.5771 81.2725C37.5629 82.9112 38.1929 84.49 39.3314 85.6687L58.0814 104.419C59.2534 105.59 60.8428 106.249 62.5001 106.249C64.1574 106.249 65.7468 105.59 66.9189 104.419L110.669 60.6687C111.807 59.49 112.437 57.9112 112.423 56.2725C112.409 54.6338 111.752 53.0662 110.593 51.9074C109.434 50.7486 107.866 50.0913 106.228 50.077C104.589 50.0628 103.01 50.6928 101.831 51.8312Z"
                        fill="#51CC47"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_4319">
                        <rect width="150" height="150" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>

                <div className="final_txt">
                  Your submission has been received!
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default QuizModal;
