import React from "react";

const ProtectAllBlock: React.FC = () => {
  return (
    <section className="section bg_green padding-150" style={{ marginTop: -1 }}>
      <div className="container">
        <div className="row gap-20">
          <div className="col-50 bg-effect-1">
            <div className="logo_title">
              <img src="assets/images/icons/logo-icon.svg" alt="" />
              <span>Nuvogate Secure</span>
            </div>
            <h2 style={{ marginBottom: 20 }}>One app to control them all.</h2>
            <p
              style={{
                fontSize: 16,
                fontWeight: "400",
                lineHeight: "29px",
                marginBottom: 33,
              }}
            >
              A special application with a good interface manages all the
              devices and keeps you connected to your home and your family's
              safety from whatever you are.
            </p>
            <ol className="numol">
              <li>
                <span>1</span>24/7 access from anywhere
              </li>
              <li>
                <span>2</span>Control alerts and quick actions
              </li>
              <li>
                <span>3</span>Check event details and statistics
              </li>
              <li>
                <span>4</span>Watch online and stored data
              </li>
            </ol>
          </div>
          <div className="col-50">
            <div className="block_img center">
              <img width="320" src="assets/images/iphone.png" alt="img" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ProtectAllBlock;
